/* You can add global styles to this file, and also import other style files */

body {
    background: #ffffff;
    color: #333;
}

.register-page {
    background: #ffffff;
    color: #333;
}

.card h1, .card h2, .card h3, .card h4, .card h5, .card h6, .card p, h1, h2, h3, h4, h5, h6 {
    color: rgb(51, 51, 51, 0.8);
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(1turn);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(1turn);
	}
}

.btn {
	position: relative;
	.btn-loader {
		position: absolute;
		z-index: 9;
		border-radius: 50%;
		border: 2px solid transparent;
		border-top-color: #fff;
		width: 18px;
		height: 18px;
		-webkit-animation: spin .5s linear infinite;
		animation: spin .5s linear infinite;
		left: calc(50% - 9px);
		top: calc(50% - 9px);
		display: none;
	}
	.btn-loader.btn-loader--blue {
		border-color: #08c;
	}
}
.btn.is--loading {
	pointer-events: none;
	.btn-loader {
		display: block;
	}
	.btn-text {
		color: transparent;
		opacity: 0;
	}
}

.navbar-brand img {
    max-width: 260px;
}


// Audio Player

*:focus
{
    outline: none;
}

#app-cover
{
    width: 290px;
    height: 100px;
    margin: 76px auto;
}

#player
{
    position: relative;
    height: 100%;
    z-index: 3;
}

#player-track
{
    position: absolute;
    top: 0;
    right: 15px;
    left: 15px;
    padding: 13px 22px 10px 184px;
    background-color: #fff7f7;
    border-radius: 15px 15px 0 0;
    transition: 0.3s ease top;
    z-index: 1;
}

#player-track.active
{
    top: -92px;
}

#album-name
{
    color: #54576f;
    font-size: 17px;
    font-weight: bold;
}

#track-name
{
    color: #acaebd;
    font-size: 13px;
    margin: 2px 0 13px 0;
}

#track-time
{
    height: 12px;
    margin-bottom: 3px;
    overflow: hidden;
}

#current-time
{
    float: left;
}

#track-length
{
    float: right;
}

#current-time, #track-length
{
    color: transparent;
    font-size: 11px;
    background-color: #ffe8ee;
    border-radius: 10px;
    transition: 0.3s ease all;
}

#track-time.active #current-time, #track-time.active #track-length
{
    color: #f86d92;
    background-color: transparent;
}

#s-area, #seek-bar
{
    position: relative;
    height: 4px;
    border-radius: 4px;
}

#s-area
{
    background-color:#ffe8ee;
    cursor: pointer;
}

#ins-time
{
    position: absolute;
    top: -29px;
    color: #fff;
    font-size: 12px;
    white-space: pre;
    padding: 5px 6px;
    border-radius: 4px;
	display:none;
}

#s-hover
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    z-index: 2;
}

#ins-time, #s-hover
{
    background-color: #3b3d50;
}

#seek-bar
{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: #fd6d94;
    transition: 0.2s ease width;
    z-index: 1;
}

#player-content
{
    position: relative;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 10px 20px #65656526;
    border-radius: 15px;
    z-index: 2;
}

#album-art
{
    position: absolute;
    top: -40px;
    width: 115px;
    height: 115px;
    margin-left: 40px;
    transform: rotateZ(0);
    transition: 0.3s ease all;
    box-shadow: 0 0 0 10px #fff;
    border-radius: 50%;
    overflow: hidden;
}

#album-art.active
{
    top: -60px;
    box-shadow: 0 0 0 4px #fff7f7, 0 30px 50px -15px #afb7c1;
}

#album-art:before
{
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 20px;
    height: 20px;
    margin: -10px auto 0 auto;
    background-color: #d6dee7;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #fff;
    z-index: 2;
}

#album-art img
{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
}

#album-art img.active
{
    opacity: 1;
    z-index: 1;
}

#album-art.active img.active
{
    z-index: 1;
    animation: rotateAlbumArt 3s linear 0s infinite forwards;
}

@keyframes rotateAlbumArt
{
    0%{ transform: rotateZ(0); }
    100%{ transform: rotateZ(360deg); }
}

#buffer-box
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 13px;
    color: #1f1f1f;
    font-size: 13px;
    font-family: Helvetica;
    text-align: center;
    font-weight: bold;
    line-height: 1;
    padding: 6px;
    margin: -12px auto 0 auto;
    background-color: rgba(255, 255, 255, 0.19);
    opacity: 0;
    z-index: 2;
}

#album-art img, #buffer-box
{
    transition: 0.1s linear all;
}

#album-art.buffering img
{
    opacity: 0.25;
}

#album-art.buffering img.active
{
    opacity: 0.8;
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

#album-art.buffering #buffer-box
{
    opacity: 1;
}

#player-controls
{
    width: 100%;
    height: 100%;
    margin: 0 5px 0 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.control
{
    padding: 12px 0;
}

.button
{
	padding: 25px;
	background-color: #fff;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
}

.button i
{
    display: block;
    color: #d6dee7;
    font-size: 26px;
    text-align: center;
    line-height: 1;
}

.button, .button i
{
    transition: 0.2s ease all;
}

.button:hover
{
    background-color: #d6d6de;
}

.button:hover i
{
    color: #fff;
}

#ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px 14px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #ae5f87;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(174, 95, 135, 0.86);
  z-index: 125;
}

p {
    color: #333333;
}

.no-streams {
	padding: 90px 60px;
	text-align: center;
	background-color: #fff7ec;
	border-radius: 13px;
}

.no-streams p {
	margin-bottom: 24px;
}

.modal {
    background-color: #04050cb8;
}

.navbar.fixed-top {
    padding: 0px !important;
}

nav.navbar.bg-danger {
    box-shadow: 0px 5px 9px 0px rgb(0 0 0 / 30%);
}

.navbar {
    min-height: 70px;
}

fieldset[disabled] .input-group-prepend {
    background-color: #1d253b;
    color: #344675;
    cursor: not-allowed;
}

.card.card-account {
    padding: 30px;
}

.riot-video-wrapper {
    width: 100vw;
    height: 100vh;
    
    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .riot-action-bar {
        position: absolute;
        top: 70%;
        width: 100%;
        text-align: center;
    }

    .btn.btn-lg {
        padding: 21px 88px;
        border-radius: 50px;
        font-size: 22px;
    }
}


.huge-circle-btn {
    border-radius: 110px;
    text-transform: uppercase;
    width: 190px !important;
    height: 190px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
}

.push-to-talk-container {
    text-align: center;
}

.huge-circle-btn:active {
    box-shadow: inset 0px 0px 17px #000 !important;
}

h4.badge.progress-bar-animated.progress-bar-striped {
    display: block;
    // max-width: 290px;
    padding: 12px;
    font-size: 15px;
    margin: 10px auto;
}

.stream-avatar {
    margin-bottom: 20px;
}

.stream-avatar .stream-info {
    margin-left: 21px;
}

.stream-avatar .stream-info p {
    color: #333 !important;
    font-size: 16px;
}

.stream-recorder a,
.stream-recorder .rec-action {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    font-size: 21px;
    color: #fff;
    border-radius: 36px;
    transition: all 0.3s;
    cursor: pointer;
}

.stream-recorder {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: -20px;
}

// .stream-recorder a:hover {
//     background-color: #fbad1f;
// }

button.btn.btn-icon.btn-red.btn-round.huge-circle-btn {
    background-image: linear-gradient(to bottom left,#ff0c0c,#a70e0e,#ff0000);
}

button.btn.btn-icon.btn-red:active,
button.btn.btn-icon.btn-red:hover,
button.btn.btn-icon.btn-red:focus {
    background-image: linear-gradient(to bottom left,#ff0c0c,#a70e0e,#ff0000) !important;
}

.btn-danger.btn-link:active, .btn-danger.btn-link:focus, .btn-danger.btn-link:hover {
    color: #333!important;
}

.modal.modal-black .modal-content {
    background-color: #fff;   
}

.modal.modal-black h1, .modal.modal-black h2, .modal.modal-black h3, .modal.modal-black h4, .modal.modal-black h5, .modal.modal-black h6, .modal.modal-black p {
    color: #333;
}

table.tablesorter {
    background-color: #fff7ec;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 2px #ffe2b9;
    margin-top: 14px;
}

table.tablesorter >tbody>tr>td, table.tablesorter >thead>tr>th {
    color: #333 !important;
    padding: 10px 20px;
}


.btn-warning.btn-link:active, .btn-warning.btn-link:focus, .btn-warning.btn-link:hover {
    color: #333!important;
}

.form-check .form-check-label {
    color: #333;
}

fieldset[disabled] .input-group-prepend {
    background-color: #e8e8e8;
    color: #333;
    cursor: not-allowed;
    border-color: #d0d0d0;

    .input-group-text {
        background-color: transparent;
        border: 1px solid #d0d0d0;
        border-radius: .4285rem;
        color: #333;
        margin: 0;
        border-right: 0px;
    }
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #e8e8e8;
    color: #333;
    cursor: not-allowed;
    border-color: #d0d0d0;
}

.nav-pills .nav-item:not(.active) .nav-link {
    color: hsl(0deg 0% 54%);
    background-color: #f1f1f1;

    &:hover {
        background-color: #e4e4e4;
    }
}

.terms-modal .modal-dialog {
    max-width: 900px;
}

.terms-modal .modal-dialog .modal-body {
    max-height: 440px;
    overflow: auto;
    margin-bottom: 25px;
    margin-right: 25px;
}

.modal.show .modal-dialog {
    transform: translateY(40px);
}

.image-uploader {
    position: relative;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.image-uploader > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000bf;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 90px;
    transform: scale(0);
    transition: all 0.3s;
    cursor: pointer;
}

.image-uploader > input {
    display: none;
}

.image-uploader > img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.image-uploader:hover > label {
    transform: scale(1);
}

.page-header>.content {
    margin-top: 70px;
}

.page-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s;
    pointer-events: none;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex;

    &.loader--active {
        opacity: 1;
        pointer-events: all;
    }

    .loadersmall {
        border: 5px solid #f3f3f3;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        border-top: 5px solid #fbad1f;
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }
}

.stream-avatar .stream-info p strong {
    font-weight: 600;
}


.stream-avatar,
.stream-recorder-wrapper,
.stream-player {
    box-shadow: 0px 0px 8px #00000024;
    border-radius: 20px;
    padding: 20px;
}

.stream-player {
    height: 100%
}

.ngx-notification {
    z-index: 99999 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body lib-ngx-notification .ngx-notification.bottom-left{
    &.warning {
        background-color: #ffd90f;
        border-color: #faf2cc;
        color: #584b05;
    }

    &.danger {
        background-color: #ff1d1d;
        border-color: #ebcccc;
        color: #ffe5e5;
    }

    &.success{
        background-color: #5dff4a;
        border-color: #d0e9c6;
        color: #143a14;
    }
}

nav#navbar-top {
    background: rgb(225 141 50);
}

.navbar .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}

.stream-title,
.stream-recorder-wrapper h4 {
    font-weight: 600;
    color: #000;
}

.record-actions {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
}

.action-list {
    display: flex;
    min-width: 140px;
    justify-content: center;
}

.record-actions p {
    margin-bottom: 0px;
    font-weight: 600;
}

.stream-recorder a i {
    line-height: 18px;
}

.stream-recorder a.rec-rewind {
    background-color: transparent;
    color: #e18d32;

    // &:hover {
    //     background-color: #ff8d103b;
    // }
}

// .stream-recorder a.rec-record,
// .stream-recorder a.rec-stop {
//     background-color: #dd0000;

//     &:hover {
//         background-color: #dd000070;
//     }
// }

.player-controls {
    margin-bottom: 10px;
    display: block;
    text-align: center;
    margin-top: 40px;
}

.player-controls label {
    min-width: 170px;
    display: block;
}


.player-controls label strong {
    display: block;
    font-weight: 600;
    font-size: 13px;
}

.player-controls .text-small {
    font-size: 11px;
    // text-align: left;
    display: block;
    margin-top: 5px;
}

.modal-content .modal-body p, 
.modal-body ul li {
    color: #000000;
}

.modal-content .modal-body .form-check.form-check-radio {
    display: inline-block;
    padding-left: 0px;
    padding-right: 20px;
}

div#broadcastPackage .modal-dialog {
    max-width: 760px;
    border-radius: 15px;
    overflow: hidden;
    border: 3px solid #ec8f22;
}

/* Audio Volume */
$track-color: #dcdcdc !default;
$thumb-color: #e18d32 !default;

$thumb-radius: 15px !default;
$thumb-height: 20px !default;
$thumb-width: 20px !default;
$thumb-shadow-size: 0px !default;
$thumb-shadow-blur: 0px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0) !default;
$thumb-border-width: 1px !default;
$thumb-border-color: #dc5b1f !default;

$track-width: 100% !default;
$track-height: 3px !default;
$track-shadow-size: 0px !default;
$track-shadow-blur: 0px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 0px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}

[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  margin: $thumb-height / 2 0;
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}
/* Audio Volume */


@media (max-width: 767px) {
    .stream-player {
        margin-top: 40px;
        margin-bottom: 0px;
        height: auto;
    }
    .stream-avatar {
        flex-direction: column;
    }
    .card-stats {
        margin-bottom: 20px;
    }
}

button.huge-circle-btn.btn-disabled {
    pointer-events: none;
    background: #b9b9b9;
}

.progress-bar-striped.badge-danger {
    background-color: #c70000;
}

.ct-footer {
    display: flex;
    margin: 50px -60px 0px;
    align-items: center;
}

.ct-footer h4 {
    font-weight: 700;
    color: #fbad1f;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    background-color: #d4d4d4;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
    background-color: #f28925;
}

#map {
    width: 100%;
    height: 480px;
}

.riotsm-step {
    position: absolute;
    top: 28px;
}

.riot-soundmeter {
    padding-bottom: 20px;
    position: relative;
}

.riotsm-step:before {
    content: "";
    width: 3px;
    height: 16px;
    background-color: #e6690a;
    display: block;
    border-radius: 10px;
}

.riotsm-label {
    font-size: 16px;
    color: #e6690a;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
}
